import React from 'react';
import './newsheader.css';


class Newsheader extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			data:JSON.parse(this.props.newsheader_data)
		}
	}
	
	componentWillUnmount () {
		window.removeEventListener('scroll',this.handleScroll,true);
		if(document.body.scrollTop){
			document.body.scrollTop = 0;
		}else{
			document.documentElement.scrollTop = 0;
		}
	}
	render() {
		return (
			<div>
				<div className="newsheader_main" style={{backgroundColor:this.state.data.color,}} ref="newsheader_main">
				<div className="newsheader_box">
					<div className="newsheader_bg" style={{backgroundImage:"url("+this.state.data.backgroundImage+")"}} ref="newsheader_bg">
					</div>	
					<div className="newsheader_title">
							<h3 ref="newsheader_title_h3" style={{background:"url("+this.state.data.title_bgp+") no-repeat"}}></h3>
							<span>{this.state.data.title}</span>
					</div>
				</div>
				</div>
			</div>
		)
	}
}
export default Newsheader;