import React from 'react';
import './introduction.css';
import intro from '../../image/intro.jpg';
import Footer from '../Footer';


class Introduction extends React.Component {
	
	componentWillUnmount () {
		if(document.body.scrollTop){
			document.body.scrollTop = 0;
		}else{
			document.documentElement.scrollTop = 0;
		}
	}
	componentWillMount () {
		
		document.title="ZeroBlockly - 公司介绍";
		
	}
	
	
	render() {	
		return (
			<div className="intro_content">
				<div className="content-title">
					<div className="title-text">公司介绍</div>
				</div>
				<div className="content-main">
					<div className="img-box ft-left">
						<img src={intro} className="main-img" />
					</div>
					<div className="text-box ft-right">
						<p>上海思禅智能科技有限公司，成立于2014年7月，主营科技教育项目，旗下拥有品牌“ZERO WORKSHOP”零号车间创客工厂。</p>
						<p>我们专注于机器人及创客教育产品的研发及课程开发，继承了机械工业制造与CNC数控加工的多年经验，融入IT科技企业精英的研发基因，依靠科技创新的理念不断发展，随着技术力量的不断壮大，形成研发，生产，教学，师资培训，比赛等全系列的青少年机器人及创客教育的生态产业链。</p>
						<p>在技术研发上，引入世界顶级半导体公司高级管理及技术团队，快速突破教育机器人行业技术难题，并且形成了有实际技术应用价值的科技成果。</p>
						<p>同时，我们计划将远程图像传输引用到教学环节中。在未来，家长们可以通过移动端在线看到孩子们的课堂情况。</p>
					</div>
				</div>
				<div className="content-title">
					<div className="title-text"> &nbsp;</div>
				</div>
				<Footer />
			</div>
		)
	}
}


export default Introduction;