import React from 'react';
import './contactus.css';
import chinaPic from '../../image/china.jpg';
import mapPic from '../../image/newMap.jpg';
import Footer from '../Footer';

class Contactus extends React.Component {
	
	componentWillUnmount () {
		if(document.body.scrollTop){
			document.body.scrollTop = 0;
		}else{
			document.documentElement.scrollTop = 0;
		}
	}
	
	componentWillMount () {
		
		document.title="ZeroBlockly - 联系我们";
		
	}
	
	handleClick = () => {
		window.open('https://map.baidu.com/poi/%E5%98%89%E5%B9%B4%E5%8D%8E%E5%A4%A7%E5%8E%A6/@13516542.943211002,3647988.186146784,13.2z?uid=fa468add7269d39ec72ba14c&primaryUid=3872686245569339925&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&querytype=detailConInfo&da_src=shareurl');
	}
	
	render() {
		return (
			<div className="contactus_main">
				<div className="contactus_title">联系我们</div>
				<div className="contactus_content">
					<div className="contactus_content_title">
						<img src={chinaPic} />
						<span>上海</span>
						<hr />
					</div>
					<div className="contactus_content_box">
						<img src={mapPic} onClick={this.handleClick} />
						<div className="contactus_content_box_text">
							<p>上海市静安区云欣路200号亚龙创业园3号楼103室</p>
							<p>Room 103, Building 3, No.200 Yunxin Rd.,Jingan District, Shanghai, China</p>
							<p>邮  编 : 200435</p>
							<p>Post Code : 200435</p>
							<p>服务热线 : 021-5650 2689</p>
							<p>Hotline : 021-5650 2689</p>
							<p>电子邮箱 : philip@ogrhino.cn</p>
							<p>Email : philip@ogrhino.cn</p>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

export default Contactus;
