import React from 'react';
import './gameInformation.css';
import Newsheader from '../news_header/news_header';
import Newsbody from '../news_body/news_body';
import t_bgp from '../../../image/head_tit_3.png';
import bgp from '../../../image/news_head_bg_3.jpg';
import Footer from '../../Footer';
import {  Link } from 'react-router-dom';

let info=[
		{
			img:'news/companynewsImg1.jpg',
			title:'零号车间X元素战队 世界机器人大赛总决赛首秀获亚军',
			content:'本次大赛分为三类独立赛事：蓝色星球、星际探索和攻城守垒。零号车间X战队本次参加的是攻城守垒赛。经过层层选拔，零号车间X战队突出重围进入总决赛，最终夺得该项赛事的亚军。',
			time:'零号车间	2018.08.02'
		},
		{
			img:'news/companynewsImg8.jpg',
			title:'零号车间 北京CICC全国青少年创客联赛 再创佳绩',
			content:'来自全国二十多个城市的青少年创客选手们进行了3D打印设计、液压机械臂、机器人挑战赛、迷宫探险挑战赛、创客作品展示评选等项目的比赛。经过激烈紧张的比拼，最终决出各个赛项的一、二、三等奖和优胜奖，并评选出创客联赛优秀组织单位和优秀指导老师。',
			time:'零号车间	2018.09.24'
		},
		{
			img:'news/companynewsImg3.jpg',
			title:'零号车间的英雄，秦始皇的亲舅舅，即将复活',
			content:'来自全国的顶级专家聚集在一起商量如何拯救短于，应为段玉当年没有死挂在树上等待很多年以后终于被人拯救下来，复活啦',
			time:'零号车间  2030.2.30'
		}


]
class GameInformation extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			gameInfo:{
				color:'#A43F39',
				backgroundImage:bgp,
				title:'了解最新资讯',
				title_bgp:t_bgp,
			}
		}
	} 
	
	componentWillMount () {
		
		document.title="ZeroBlockly - 比赛信息";
		
	}
	
	componentWillUnmount () {
		if(document.body.scrollTop){
			document.body.scrollTop = 0;
		}else{
			document.documentElement.scrollTop = 0;
		}
	}
	
	render() {
		let newsheader_data = JSON.stringify(this.state.gameInfo);
		return (
			<div>
				<Newsheader newsheader_data={newsheader_data} />
				<div className="companynews_main">
					<Link to="/CNewsOne"><Newsbody info={info[0]} /></Link>
					<Link to="/CNewsFour"><Newsbody info={info[1]} /></Link>
					<Link to="/CNewshead"><Newsbody info={info[2]} /></Link>
				</div>
				<Footer />
			</div>
		)
	}
}
export default GameInformation;
