import React from 'react';
import { Route , Switch } from 'react-router-dom';
import Header from '../components/Header';       //这是头部导航组件
//import RootBreadcrumb from '../components/RootBreadcrumb';
import Footer from '../components/Footer';      //这是首页底部信息
import Home from '../components/Home';			//这是首页滚动加载
import Introduction from '../components/Introduction/Introduction';//关于我们下拉，公司介绍
import Download from '../components/DownloadCenter/Download';//下载中心里边的内容
import Contactus from '../components/Contact/Contactus';//首页关于我们下拉，联系方式
import Companynews from '../components/news/companynews/companynews';//首页新闻中心，活动资讯
import ActivityInformation from '../components/news/activityInformation/ActivityInformation';//首页新闻中心，企业新闻
import GameInformation from '../components/news/gameInfo/GameInformation';//首页新闻中心，比赛信息
import Curriculum from '../components/Curriculum/Curriculum.js';//首页课程中心
import CoursePage from '../components/Curriculum/CoursePage/CoursePage';//课程中心，里边详情
import CnewsOne from '../components/news/companynews/news/CNewsOne';//新闻中心，里边的详情
import CnewsTwo from '../components/news/companynews/news/CNewsTwo';//新闻中心，里边的详情
import Cnewshead from '../components/news/companynews/news/CNewshead';
import CnewsThree from '../components/news/companynews/news/CNewsThree';//新闻中心，里边的详情
import CnewsFour from '../components/news/companynews/news/CNewsFour';//新闻中心，里边的详情
import CnewsFive from '../components/news/companynews/news/CNewsFive';//新闻中心，里边的详情
import CnewsSix from '../components/news/companynews/news/CNewsSix';//新闻中心，里边的详情
import CnewsSeven from '../components/news/companynews/news/CNewsSeven';//新闻中心，里边的详情
import CnewsEight from '../components/news/companynews/news/CNewsEight';//新闻中心，里边的详情
import Production from '../components/Production/Production';//产品中心，详情
import Shop from '../components/Shop/Shop.js';//商城待开发.......

export const RootHeader = () => 
	<Route path="*" component={ Header } />

//export const RootBreadcrumbRoute = () => 
//	<Route path="*" component={ RootBreadcrumb } />

export const FooterRoute = () => 
	<Route path="*" component={ Footer }  />

export const ContentRoute = () =>
	<Switch>
		<Route exact path="/" component={ Home }  />
		<Route exact path="/home" component={ Home }  />
		<Route exact path="/intro" component={ Introduction } />
		<Route exact path="/contact" component={ Contactus } />
		<Route exact path="/download" component={ Download } />
		<Route exact path="/companynews" component={ Companynews } />
		<Route exact path="/activityInformation" component={ ActivityInformation } />
		<Route exact path="/gameInformation" component={ GameInformation } />
		<Route exact path="/production" component={ Production } />
		<Route exact path="/curriculum" component={ Curriculum } />
		<Route exact path="/curriculum/coursePage" component={ CoursePage } />
		<Route exact path="/CNewsOne" component={ CnewsOne } />
		<Route exact path="/CNewshead" component={ Cnewshead} />
		<Route exact path="/CNewsTwo" component={ CnewsTwo } />
		<Route exact path="/CNewsThree" component={ CnewsThree } />
		<Route exact path="/CNewsFour" component={ CnewsFour } />
		<Route exact path="/CNewsFive" component={ CnewsFive } />
		<Route exact path="/CNewsSix" component={ CnewsSix } />
		<Route exact path="/CNewsSeven" component={ CnewsSeven } />
		<Route exact path="/CNewsEight" component={ CnewsEight } />
		<Route exact path="/shop" component={ Shop } />
	</Switch>
































