import React from 'react';
import { Row , Col } from 'antd';
import './download.css';
import Item_inner from './item_inner/Item_inner';
import img from '../../image/gradingTestG.png';
import img_hover from '../../image/gradingTestB.png';
import itembgp from '../../image/item_bgp.png';
import itembgp2 from '../../image/item_bgp2.png';
import itembgp3 from '../../image/item_bgp3.png';
import img_courseG from '../../image/courseG.png';
import img_hover_courseB from '../../image/courseB.png';
import img_documentDataG from '../../image/documentDataG.png';
import img_hover_documentDataB from '../../image/documentDataB.png';
import Footer from './../Footer';

class Download extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gradingTest:{
				frontPic:img_hover,
				behindPic:img,
				title:'编程软件',
				version:'3.1.0',
				date:'2020-04-06',
				downLink:"download/ZeroBlocklyV3.1.0.msi",
				logLink:'',
				backgroungImage:itembgp,
				btn:'Down'
			},
			course:{
				frontPic:img_hover_courseB,
				behindPic:img_courseG,
				title:'编程体验',
				version:'1.2.3',
				date:'2019-1-23',
				downLink:"/apps/mixly/index_ZeroWorkshop_AllSeries.html",
				logLink:'',
				backgroungImage:itembgp2,
				btn:'Try'
			},
			documentData:{
				frontPic:img_hover_documentDataB,
				behindPic:img_documentDataG,
				title:'帮助手册',
				version:'1.0.1',
				date:'2019-11-08',
				downLink:"download/document/HandbookforGradeIIIExamination.rar",
				logLink:'',
				backgroungImage:itembgp3,
				btn:'Down'
			}
		}
	}
	
	componentWillMount () {
		
		document.title="ZeroBlockly - 下载中心";
		
	}
	
	componentWillUnmount () {
		if(document.body.scrollTop){
			document.body.scrollTop = 0;
		}else{
			document.documentElement.scrollTop = 0;
		}
	}
	
	
	render () {
		let gradingTestData = (JSON.stringify(this.state.gradingTest));
		let courseData = (JSON.stringify(this.state.course));
		let documentData = (JSON.stringify(this.state.documentData));
		return (
			<div className="downloadcenter">
				<Row>
					<Col span={4} offset={5} className="gradingTest"><Item_inner data={gradingTestData} /></Col>
					<Col span={4} offset={1} className="course" ><Item_inner data={courseData} /></Col>
					<Col span={4} offset={1} className="document"><Item_inner data={documentData} /></Col>
				</Row>
				<Footer />
			</div>
		)
	}
}

export default Download;
