import React from 'react';
import './Slider.css';
import lamp from '../../image/sliderItem1.JPG';
import monster from '../../image/sliderItem2.JPG';
import walle from '../../image/sliderItem3.JPG';
import {Icon} from 'antd';


let config = [
		{
			"width": 40,
			"opacity": '0.7',
			"left": '3%',
			"top": 8,
			"z-index":'0',
			"filter":'brightness(0.5)'
		},
		{
			"width": 40,
			"opacity": '0.7',
			"left": '56%',
			"top": 8,
			"z-index":'0',
			"filter":'brightness(0.5)'
		},
		{
			"width": 60,
			"opacity": '1',
			"left": '21%',
			"top": 3,
			"z-index":'2',
			"filter":'brightness(1)'
		}
	]

let flag = true,num = 1;

class Slider extends React.Component {
		
	constructor(props){
		super(props);
		this.state={
			imgs:[
				{
					a:'sliderItem1.JPG',
					b:'sliderItem2.JPG',
					c:'sliderItem3.JPG'
				},
				{
					a:'t11.jpg',
					b:'t12.jpg',
					c:'t13.jpg'
				},
				{
					a:'t21.jpg',
					b:'t22.jpg',
					c:'t23.jpg'
				},
				{
					a:'t31.jpg',
					b:'t32.jpg',
					c:'t33.jpg'
				},
				{
					a:'t41.jpg',
					b:'t42.jpg',
					c:'t43.jpg'
				},
				{
					a:'t51.jpg',
					b:'t52.jpg',
					c:'t53.jpg'
				},
				{
					a:'production2.jpg',
					b:'production1.jpg',
					c:'production3.jpg'
				},
			
			
			]
		}
	}
	
	animate = (obj,json,fn) => {
		clearInterval(obj.timer);
		obj.timer = setInterval(function () {
			let f = true;
			for(let k in json){
				if( k == "opacity"){
					let leader = obj.style[k]*100;
					let target = json[k]*100;
					let step = (target -leader)/10;
					step = step>0 ? Math.ceil(step):Math.floor(step);
					leader = step + leader;
					obj.style[k] = leader/100;
				}
				else if( k == "width"){
					let leader = parseFloat(obj.style[k]);
					let target = json[k];
					let step = (target - leader)/10;
					leader = leader+step;
					obj.style[k]=leader+'%';
				}else if( k == "z-index"){
					obj.style[k] = json[k];
				}else if( k == "filter"){
					obj.style[k] = json[k];
				}else if( k == "top"){
					let leader = parseFloat(obj.style[k]);
					let target = json[k];
					let step = (target - leader)/10;
					leader = leader+step;
					obj.style[k]=leader+'rem';
				}else if( k == "left"){
					let leader = parseInt(obj.style[k]);
					let target =  parseInt(json[k]);
					let step = (target-leader)/10;
					step = step>0 ? Math.ceil(step):Math.floor(step);
					leader = step+leader;
					obj.style[k] = leader+'%';
				
					if (leader != target) {
                    	f = false;
                	}
				}
				
			}
			 if (f) {
                clearInterval(obj.timer);
                if (fn) {
                    fn();
                }
            }
		},17)
		
	}
	assign = () => {
		let lis = this.refs.slider_ul.children;
		for(let i=0 ; i<lis.length ; i++){
			this.animate(lis[i],config[i],function(){
				flag = true;
			});
		}
	}
	prev = () => {
		if(this.props.handleOver){
		num--;
		if(num<0){
			num=2;
		}
		this.props.handleOver(num);
		}
        if (flag) {
            flag = false;
            config.push(config.shift());
            this.assign();
        }
	}
	next = () => {
		if(this.props.handleOver){
		num++;
		if(num>2){
			num=0;
		}
		this.props.handleOver(num);}
		if(flag){
			flag = false;
			config.unshift(config.pop());
			this.assign();
		}
	}
	
	render() {	
		let a = require('./../../image/'+this.state.imgs[this.props.index].a);
		let b = require('./../../image/'+this.state.imgs[this.props.index].b);
		let c = require('./../../image/'+this.state.imgs[this.props.index].c);
		return (
			<div className="slider">
				<div className="leftButton" onClick={this.prev}>
					<Icon type="left-circle" theme="twoTone" twoToneColor="#E50A5A"/>
				</div>
				<div className="rightButton" onClick={this.next}>
					<Icon type="right-circle" theme="twoTone"  twoToneColor="#E50A5A" />
				</div>
				<ul ref="slider_ul" >
					<li className='sliderItem' style={{width: "40%",opacity: 0.7,left: "3%",top: "8rem",zIndex:0,filter:'brightness(0.5)'}}><a><img src={a}  /></a></li>
					<li className='sliderItem' style={{width: "40%",opacity: 0.7,left: "56%",top: "8rem",zIndex:0,filter:'brightness(0.5)'}}><a><img src={b}  /></a></li>
					<li className='sliderItem' style={{width: "60%",opacity: 1,left: "21%",top: "3rem",zIndex:3,filter:'brightness(1)'}}><a><img src={c}  /></a></li>
				</ul>
				
			</div>
		)
	}
}



export default Slider;

