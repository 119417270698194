import React , { Fragment } from 'react';
import { Icon } from 'antd';
import './CoursePage.css';
import video from  './../../../image/video/titi.mp4';
import posterS1_1 from './../../../image/posterS1-1.png';
import Footer from './../../Footer';
import img from './../../../image/levelS/insect/insect(1).PNG';



export default class CoursePage extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			tabFlag:false,
			data:{
				"S":{
					's1':{
						'title':'【2019-春】S1课程第一课昆虫机器人',
						'sub':'昆虫即动物界昆虫纲的动物，所有生物中种类及数量最多的一群，是世界上最繁盛的动物，已发现100多万种 。',
						'coefficient':'1',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/titi.mp4'),
						'pic':'e/levelS/insect/insect',
						'picNum':'41',
						'poster':require('./../../../image/levelS/insect/insect(1).PNG')
					},
					's2':{
						'title':'【2019-春】S1课程第二课飞机机器人',
						'sub':'美国现代最著名的科普作家、科幻小说家阿西莫夫就提出了机器人 三大安全法则，保证了机器人不会伤害人类。',
						'coefficient':'1',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/plane.mp4'),
						'pic':'e/levelS/plane/plane',
						'picNum':'28',
						'poster':require('./../../../image/levelS/plane/plane(1).PNG')
					},
					's3':{
						'title':'【2019-春】S1课程第三课碰碰车机器人',
						'sub':'计算机技术和传感器的发展推动了机器人的发展，导致有感觉（即具有听觉、视觉、触觉等功能）的机器人陆续研制成功。 ',
						'coefficient':'2',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/dodgem.mp4'),
						'pic':'e/levelS/dodgem/dodgem',
						'picNum':'29',
						'poster':require('./../../../image/levelS/dodgem/dodgem(1).PNG')
					},
					's4':{
						'title':'【2019-春】S1课程第四课青蛙机器人',
						'sub':'青蛙的后腿非常发达，跳跃能力特别强，一只普通的成年牛蛙，能跳越它身长9倍的距离。',
						'coefficient':'2',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/frog.mp4'),
						'pic':'e/levelS/frog/frog',
						'picNum':'29',
						'poster':require('./../../../image/levelS/frog/frog(1).PNG')
					},
					's5':{
						'title':'【2019-春】S1课程第五课恐龙机器人',
						'sub':'机器人动力驱动的方式有液压、气压和电力驱动等，目前应用方式最广的是电力驱动。',
						'coefficient':'3',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/dinosaur.mp4'),
						'pic':'e/levelS/dinosaur/dinosaur',
						'picNum':'26',
						'poster':require('./../../../image/levelS/dinosaur/dinosaur(1).PNG')
					},
					's6':{
						'title':'【2019-春】S1课程第六课巡逻机器人',
						'sub':'巡逻车会经常在我们周围行动，防止犯罪和事故的发生，起到保护安全的作用。',
						'coefficient':'3',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/patrol.mp4'),
						'pic':'e/levelS/patrol/patrol',
						'picNum':'34',
						'poster':require('./../../../image/levelS/patrol/patrol(1).PNG')
					},
					's7':{
						'title':'【2019-春】S1课程第七课蟑螂机器人',
						'sub':'神奇聪明的机器人罗奇可以在碰到物体之前通过红外线传感器预先感知，从而改变方向，也可以探测危险断壁。',
						'coefficient':'4',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/cockroach.mp4'),
						'pic':'e/levelS/cockroach/cockroach',
						'picNum':'28',
						'poster':require('./../../../image/levelS/cockroach/cockroach(1).PNG')
					},
					's8':{
						'title':'【2019-春】S1课程第八课鳄鱼机器人',
						'sub':'鳄鱼是迄今发现活着的最早和最原始的爬行动物，它是在三叠纪至白垩纪的中生代（约两亿年以前），由两栖类进化而来，延续至今仍是半水生性凶猛的爬行动物。',
						'coefficient':'4',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/crocodile.mp4'),
						'pic':'e/levelS/crocodile/crocodile',
						'picNum':'33',
						'poster':require('./../../../image/levelS/crocodile/crocodile(1).PNG')
					},
					's9':{
						'title':'【2019-春】S1课程第九课战斗机器人',
						'sub':'战斗机器人是指以比赛为目的,最少两个机器人在各种规则的前提下攻击对方,使对方的机器人损坏,或者将对方推出规定的范围,从而获胜。',
						'coefficient':'5',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/combat.mp4'),
						'pic':'e/levelS/combat/combat',
						'picNum':'28',
						'poster':require('./../../../image/levelS/combat/combat(1).PNG')
					},
					's10':{
						'title':'【2019-春】S1课程第十课游戏机器人',
						'sub':'机器人分为工业机器人和特种机器人。应用在军事、交通、服务、娱乐、农业等等都属于非工业生产，即都是特种机器人',
						'coefficient':'6',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/game.mp4'),
						'pic':'e/levelS/game/game',
						'picNum':'31',
						'poster':require('./../../../image/levelS/game/game(1).PNG')
					},
					's11':{
						'title':'【2019-春】S1课程第六课娱乐机器人',
						'sub':'真正的随机数是使用物理现象产生的：比如掷钱币、骰子、转轮、使用电子元件的噪、 音、核、裂、变等等。',
						'coefficient':'6',
						'explain':'课程有效期：2018-10-11至2022-01-31',
						'video':require('./../../../image/video/amusement.mp4'),
						'pic':'e/levelS/amusement/amusement',
						'picNum':'27',
						'poster':require('./../../../image/levelS/amusement/amusement(1).PNG')
					},
					
					
				}
			},
			propsData:this.props.location.state?this.props.location.state:{
				type:'steam',theme:'S',page:1
			},
		}
		
	}
	
	handleClickTabsPPT = () => {
		this.setState({
			tabFlag:true
		});
		let v = this.refs.video;
		v.pause();
	}
	handleClickTabsVideo = () => {
		this.setState({
			tabFlag:false
		});
	}
	
	render() {
		let theme=this.state.propsData.theme;
		let page ='s'+this.state.propsData.page;
		let coeff=[];
		for(let i = 0 ; i < this.state.data[theme][page].coefficient ; i++){
			coeff.push(<Icon key={i} type="heart" theme="filled" />)
		}
		let imgs = [];
		for(let i = 0 ; i < this.state.data[theme][page].picNum ; i++){
			let u = require('./../../../imag'+this.state.data[theme][page].pic+"("+parseInt(i+1)+").PNG");
			imgs.push(<img key={i} src={u} />);
		}
		return (
			<Fragment>
				<div className="coursePage">
					<div className="courseMsg">
						<div className="courseTitle">
							{this.state.data[theme][page].title}
						</div>
						<div className="courseSub">
							{this.state.data[theme][page].sub}
						</div>
						<div className="courseCoefficient">
							难度:&nbsp;&nbsp;&nbsp;&nbsp;{coeff}
						</div>
						<div className="courseExplain">
							{this.state.data[theme][page].explain}
						</div>
					</div>
					
					<div className="courseInner">
						<div className="tabs">
							<div className="courseDetail" onClick={this.handleClickTabsVideo} style={{backgroundColor:this.state.tabFlag?'#fff':'#F6AC34'}}>课程视频</div>
							<div className="courseDetail" onClick={this.handleClickTabsPPT} style={{backgroundColor:this.state.tabFlag?'#F6AC34':'#fff'}}>课程详情</div>
						</div>
						<div className="courseVideo" style={{display:this.state.tabFlag?'none':'block'}}>
							<video ref="video" src={this.state.data[theme][page].video} poster={this.state.data[theme][page].poster}  controls="controls">
							您的浏览器不支持video标签
							</video>
						</div>
						<div className="courseppt"  style={{display:this.state.tabFlag?'block':'none'}}>
							{imgs}
						</div>
					</div>
					<Footer />
				</div>
			</Fragment>
		)
	}
}
