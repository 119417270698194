import React from 'react';
import './activityInformation.css';
import Newsheader from '../news_header/news_header';
import Newsbody from '../news_body/news_body';
import t_bgp from '../../../image/head_tit_2.png';
import bgp from '../../../image/news_head_bg_2.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

let info=[
		{
			img:'news/companynewsImg4.jpg',
			title:'《成长在路上》第六季玉树站 DAY1',
			content:'本次玉树活动是《成长在路上》公益行动与中国机器人教育联盟《给每一个孩子一台机器人》公益活动第一次联手合作，共同推出，希望让更多的孩子拥有学习机器人的机会！',
			time:'零号车间	2018.09.03'
		},
		{
			img:'news/companynewsImg5.jpg',
			title:'《成长在路上》第六季玉树站 DAY2',
			content:'2018年9月15日8点30分，中国电子学会第三期全国青少年机器人技术等级考试在全国各省、市同时启动。本次等级考试适逢全国科普日，以机器人技术为代表的青少年能力测评活动再次引爆全国科普教育届的热情。据初步统计，全国共计400余个考场近2万人次参加了本次考试。',
			time:'零号车间	2018.08.04'
		},
		{
			img:'news/companynewsImg6.jpg',
			title:'《成长在路上》第六季江西站 DAY2',
			content:'8月12日成长在路上踏上遂川寻找革命老兵的旅程。',
			time:'零号车间	2018.08.14'
		},
		{
			img:'news/companynewsImg7.jpg',
			title:'《成长在路上》第六季江西站 DAY3',
			content:'公益行的第三天，成长在路上助学团队踏上探访特困家庭的旅途。',
			time:'零号车间	2018.08.15'
		}



]

class ActivityInformation extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			activityInfo:{
				color:'#130325',
				backgroundImage:bgp,
				title:'丰富精神生活',
				title_bgp:t_bgp,
			}
		}
	} 
	
	componentWillMount () {
		
		document.title="ZeroBlockly - 活动资讯";
		
	}
	
	componentWillUnmount () {
		if(document.body.scrollTop){
			document.body.scrollTop = 0;
		}else{
			document.documentElement.scrollTop = 0;
		}
	}
	
	render() {
		let newsheader_data = JSON.stringify(this.state.activityInfo);
		return (
			<div>
				<Newsheader newsheader_data={newsheader_data} />
				<div className="companynews_main">
					<Link to="/CNewsFive"><Newsbody info={info[0]} /></Link>
					<Link to="/CNewsSix"><Newsbody info={info[1]} /></Link>
					<Link to="/CNewsSeven"><Newsbody info={info[2]} /></Link>
					<Link to="/CNewsEight"><Newsbody info={info[3]} /></Link>
				</div>
				<Footer />
			</div>
		)
	}
}
export default ActivityInformation;
