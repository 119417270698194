import React , { Fragment }from 'react';
import './PartTwo.css';	
import { Icon } from 'antd';
import videoPoster from './../../../image/carousel_3.jpg';
import videoWL from './../../../image/video/WL.mp4';

export default class PartOne extends React.Component {
	constructor (props) {
		super(props);
		this.state={
			flag:false,
			icon:'play-circle',
			data:[
				{
					pauseCircle:'pause-circle',
				},
				{
					playCircle:'play-circle',
					
				}
			]
		}
	}
	
	componentDidMount () {
		this.props.onRef(this);
	}
	stop = () => {
		let v =this.refs.video;
			v.pause();
			this.setState({
				icon:'play-circle',
				flag:false
			})
			
		
				
	}
	
	handleVideo = (flag) => {
		let v =this.refs.video;
		if(!flag){
				v.play();
			this.setState({
				icon:'pause-circle',
				flag:!this.state.flag
			})
			
		
		}else{
			v.pause();
			this.setState({
				icon:'play-circle',
				flag:!this.state.flag
			})
			
		}
		
		v.onended=()=>{
			this.setState({
				icon:'play-circle',
				flag:!this.state.flag
			})
		}
		
	}
	
	render() {
		return (
			<Fragment>
				<div className="partTwoMain">
					<div className="video">
						<video ref="video"    src={videoWL}   type="video/mp4"  poster={videoPoster} style={{objectFit:"fill",height:'100%',position:'inherit'}} >
							您的浏览器不支持video标签
						</video>
						<div  className="videoButton fontW" onClick={()=>this.handleVideo(this.state.flag)}>
							<Icon type={this.state.icon}  style={{fontSize:"8rem",opacity:this.state.flag?'0.5':'1'}} />
						</div>
					</div>
					<div className="videoDesc" style={{display:this.state.flag?'none':'block'}}>
						<div className="videoTitle fontW">
							全能瓦力王
						</div>
						<div className="videoDescButton fontW">
							才艺王
						</div>
						<div className="videoDescText fontW">
							<p>强大的CPU领航人工智能</p>
							<p>自由编程提供更多可能</p>
							<p>高度灵活搭接材料&nbsp;给你拓展空间</p>
						</div>
						
					</div>
				</div>
			</Fragment>
			
		)
	}
	
	
	
}

















































































































































