import React from 'react';
import { Row, Col ,Icon} from 'antd';
import { Link } from 'react-router-dom';
const wangjingimg = require('../image/WechatIMG5.png');

class Footer  extends React.Component {
	
	
	
	render() {
	return (
		<div className="f_bar">
			<Row>
			<Col span={22} push={1}>
			<div className="f_content">
					<Row>
						<Col span={8}>
							<h2>上海一禅智能科技有限公司</h2>
						</Col>
						<Col span={8}>
							<div>
								<h4>
								<svg className="icon" aria-hidden="true">
   									<use xlinkHref="#icon-dianhua"></use>
								</svg>
								&nbsp;服务热线：021-5650 2689</h4>
							</div>
						</Col>
						<Col span={8}>
							<div>
								<h4>
								<svg className="icon" aria-hidden="true">
   									<use xlinkHref="#icon-dizhi"></use>
								</svg>
								&nbsp;公司地址：上海市静安区云欣路200弄亚龙创业园3号楼103室</h4>
							</div>
						</Col>	
						
					</Row>
				</div>
			<div className="f_content">
				<hr />
			</div>
			<div className="f_content" style={{textAlign:"center"}}> 
				<h5>版权所有©上海一禅智能科技有限公司  ICP许可证号 <a href="https://beian.miit.gov.cn/" title="点击" target="blank"><font color="#000000">沪ICP备18029692号-1</font></a></h5>
				<h5><img src={wangjingimg} /><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602005981" title="点击" target="blank"><font color="#000000">沪公网安备 31010602005981号</font></a></h5>
			</div>
			</Col>
			</Row>
			
		</div>
	)
	}
}


export default Footer;