import React , { Fragment }from 'react';
import { Progress } from 'antd';
import './PartOne.css';	
import { observable ,action } from 'mobx';
import { observer ,Provider } from 'mobx-react';
class MyBarnnerSatate{
	@observable num=0;
	@observable percent=[
		{percent:0},{percent:0},{percent:0},{percent:0}
	]
	@action changeNum=(index)=>{
		this.num=index;
	}
}

const store = new MyBarnnerSatate();
let interval;

@observer
class PartOne extends React.Component {
	constructor (props) {
		super(props);
		this.state={
			barNum:[
				{index:1,color:"#F26D8C",style:{
				backgroundImage:"url("+require("./../../../image/carousel_1.jpg")+")"
			}},
				{index:2,color:"#F26D8C",style:{
				backgroundImage:"url("+require("./../../../image/carousel_2.jpg")+")"
			}},
				{index:3,color:"#F26D8C",style:{
				backgroundImage:"url("+require("./../../../image/carousel_3.jpg")+")"
			}},
				{index:4,color:"#F26D8C",style:{
				backgroundImage:"url("+require("./../../../image/carousel_4.jpg")+")"
			}}
			]
		}
	}
	
	
	componentDidMount () {
		this.percent(0);
	}
	
	percent = (number) => {
		clearInterval(interval);
		if(number>=4){
			number = 0;
		}
		store.num=number;
		store.percent.map((item,index)=>{
			item.percent=0;
			if(number == index){
				item.percent = 0;
			}
		});
		interval = setInterval(()=>{
					store.percent[number].percent++;
					if(store.percent[number].percent>=100){
						clearInterval(interval);
						this.percent(number+1);
					}
		},50);
		
	}
	
	handleMouseEnter = (index) => {
		store.num=index-1;
		this.percent(index-1);
		
	}
	
	render() {
		return (
			<Fragment>
				<div className="wheelPlanting" style={this.state.barNum[store.num].style} >
				</div>
				<div className="progressBar">
					{this.state.barNum.map((item,index)=>{
					return	<div className="item-Bar"  dataindex={item.index} key={index}><Progress strokeColor={item.color} percent={store.percent[index].percent} showInfo={false} strokeWidth={2} onMouseEnter={(e)=>this.handleMouseEnter(item.index,e)} /></div>
					})}
					
				</div>
			</Fragment>
			
		)
	}
	
	
	
}


export default PartOne;
















































































































































