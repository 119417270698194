import React from 'react';
import { Empty,Button } from 'antd'; 
import { Link } from 'react-router-dom';
import Footer from './../Footer';

export default class Shop extends React.Component{
	
	render() {
		return (
			<div style={{marginTop:'16rem',backgroundColor:'#fff',width:'100%',height:'100%'}}>
			<Empty
    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
    description={
      <span style={{fontSize:'2rem'}}>
       商品备货中...
      </span>
    }
  >
  </Empty>
  <Footer></Footer>
  </div>
		)
	}
	
}
