import React from 'react';
import { Layout, Menu, Badge, Icon, Avatar } from 'antd';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu ;
const headerPic = require('../image/header_pic2.png');

class Header extends React.Component {
	state =  {
		current: 'home'
	}
	
	
	handleClick = (e) => {
//		console.log(e.key) 
		this.setState({
			current: e.key
		})
	}
	
	
	render() {
		return (
			<div className="header" id="header" >
				<div className="heaer_img">
					<img  src={headerPic} style={{width:"100%", marginLeft:'5%'}}  />
				</div>
				<Menu
					theme="light "
					onClick={this.handleClick}
					selectedKeys={[this.state.current]}
					mode="horizontal"
					style={{float:"right",marginRight:'8%',backgroundColor:'transparent'}}
				>
					<Menu.Item key="home">
						<Link to="/home"><Icon type="home" theme="filled" />首页</Link>
					</Menu.Item>
					
					<Menu.Item key="lesson">
						<Link to="/curriculum"><Icon type="database" theme="filled" />课程中心</Link>
					</Menu.Item>
					
					
					
					<SubMenu title={<span className="submenu-title-wrapper"><Icon type="star" theme="filled" />产品中心</span>}>
						<Menu.Item key="STEAM">
							<Link to={{
								pathname:"/Production",
								state:{'key':'0'}
							}}  replace><Icon type="smile" theme="filled" /> STEAM课程</Link>
						</Menu.Item>
						<Menu.Item key="LT">
							<Link to={{
								pathname:"/Production",
								state:{'key':'1'}
							}}  replace><Icon type="smile" theme="filled" />考级&竞赛</Link>
						</Menu.Item>
						<Menu.Item key="LB">
							<Link to={{
								pathname:"/Production",
								state:{'key':'2'}
							}}  replace><Icon type="smile" theme="filled" />创客实验室</Link>
						</Menu.Item>
					</SubMenu>
					
					<Menu.Item key="tech">
						<Link to="/download"><Icon type="schedule" theme="filled" />下载中心</Link>
					</Menu.Item>
					
					<SubMenu title={<span className="submenu-title-wrapper"><Icon type="fire" theme="filled" />新闻中心</span>}>
						<Menu.Item key="companynews">
							<Link to="/companynews"><Icon type="schedule" theme="filled" />企业新闻</Link>
						</Menu.Item>
						<Menu.Item key="activityInformation">
							<Link to="/activityInformation"><Icon type="schedule" theme="filled" />活动资讯</Link>
						</Menu.Item>
						<Menu.Item key="gameInformation">
							<Link to="/gameInformation"><Icon type="schedule" theme="filled" />比赛信息</Link>
						</Menu.Item>
					</SubMenu>
					
					<SubMenu  title={<span className="submenu-title-wrapper"><Icon type="heart" theme="filled" />关于我们</span>}>
						<Menu.Item key="intro">
							<Link to="/intro"><Icon type="schedule" theme="filled" />公司介绍</Link>
						</Menu.Item>
						<Menu.Item key="contact">
							<Link to="/contact"><Icon type="schedule" theme="filled" />联系方式</Link>
						</Menu.Item>
					</SubMenu>
					
					<Menu.Item key="shop" >
						<Link  to="/shop"><Icon type="shop" theme="filled" />商城</Link>
					</Menu.Item>
					
				</Menu>
			</div>
		)
	}
}
export default Header;