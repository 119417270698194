import React , { Fragment }from 'react';
import { Link } from 'react-router-dom';
import './PartThree.css';
import organizationBG from './../../../image/organizationBG.png';
import schoolBG from './../../../image/schoolBG.png';
import competitionBG from './../../../image/competitionBG.png';

class PartThreeItem extends React.Component {
	
	constructor (props) {
		super(props);
	}
	
	
	render() {
		return (
			<Fragment>
			<Link to="/curriculum">
				<div className="item-box" style={{backgroundImage:"url("+this.props.data.bg+")",marginLeft:this.props.data.position}}>
					<div className="mask">
					<div className="item-title">
						{this.props.data.title}
					</div>
					<div className="item-sub">
						{this.props.data.sub}
					</div>
					</div>
					</div>
					</Link>
			</Fragment>
		)
	}
	
}



export default class PartThree extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			style:{
				backgroundImage:"url("+require("./../../../image/partTBG.jpg")+")"
			},
			data:[{
				title:'STEAM',
				sub:'针对教育机构',
				bg:organizationBG,
				position:'6%'
			},{
				title:'创客实验室',
				sub:'针对学校',
				bg:schoolBG,
				position:'26%'
			},{
				title:'考级&竞赛',
				sub:'针对机构、学校',
				bg:competitionBG,
				position:'46%'
			}
			]
		}
		
	}
	
	render() {
		return (
			<Fragment>
				<div className="partThreeMain" style={this.state.style}>
					<div className="partThreetext">
						<div className="partThreeTitle">
							项目服务
						</div>
						<div className="partThreeDesc">
							全方位机器人教育合作,服务多维度客户
						</div>
					</div>
					<div className="partThreeItems">
						<PartThreeItem data= {this.state.data[0]} />
						<PartThreeItem data= {this.state.data[1]} />
						<PartThreeItem data= {this.state.data[2]} />
					</div>
				</div>
				
			</Fragment>
		)
	}
}
