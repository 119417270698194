import React from 'react';
import "./curriculum.css";
import Uncomplete from './../Uncomplete.js';
import currBanner from './../../image/currBanner.jpg';
import { Row ,Col ,Icon } from 'antd';
import Footer from './../Footer';
import { observable ,action } from 'mobx';
import { observer ,Provider } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';

class MyChangeSatate{
	@observable type=0;
	@observable stageNum=0;
	@observable name='S级别';
}

const store = new MyChangeSatate();
const confirm = Modal.confirm;
@observer
class Curriculum extends React.Component {
	constructor (props) {
		super(props);
		this.state={
			stage:[
				['S级别','T级别','E级别','A级别','M级别'],
			],
			data:{'S级别':[
						{
							'title':"S1第一课昆虫机器人",
							'bg':'imgS/insect.jpg'
						},
						{
							'title':"S1第二课飞机机器人",
							'bg':'imgS/plane.jpg'
						},
						{
							'title':"S1第三课碰碰车机器人",
							'bg':'imgS/dodgem.jpg'
						},
						{
							'title':"S1第四课青蛙机器人",
							'bg':'imgS/frog.jpg'
						},
						{
							'title':"S1第五课恐龙机器人",
							'bg':'imgS/dinosaur.jpg'
						},
						{
							'title':"S1第六课巡逻机器人",
							'bg':'imgS/patrol.jpg'
						},
						{
							'title':"S1第七课蟑螂机器人",
							'bg':'imgS/cockroach.jpg'
						},
						{
							'title':"S1第八课鳄鱼机器人",
							'bg':'imgS/crocodile.jpg'
						},
						{
							'title':"S1第九课战斗机器人",
							'bg':'imgS/combat.jpg'
						},
						{
							'title':"S1第十课游戏机器人",
							'bg':'imgS/game.jpg'
						},
						{
							'title':"S1第十一课娱乐机器人",
							'bg':'imgS/amusement.jpg'
						},
						],
					'T级别':[
						{
							'title':"T1第一课魔力赛车",
							'bg':'imgT/racing.jpg'
						},
						{
							'title':"T1第二课吉猫凯蒂",
							'bg':'imgT/cat.jpg'
						},
						{
							'title':"T1第三课雷神战车",
							'bg':'imgT/thor.jpg'
						},
						{
							'title':"T1第四课恐龙先锋",
							'bg':'imgT/dinosaurT1.jpg'
						},
						{
							'title':"T1第五课飞鸟伯德",
							'bg':'imgT/bird.jpg'
						},
						{
							'title':"T1第六课灌篮高手",
							'bg':'imgT/basket.jpg'
						},
						{
							'title':"T2第一课哥利亚战车",
							'bg':'imgT/geliya.jpg'
						},
						{
							'title':"T2第二课弗兰德台灯",
							'bg':'imgT/fulande.jpg'
						},
						{
							'title':"T2第三课追日震天弩",
							'bg':'imgT/followthesun.jpg'
						},
						{
							'title':"T2第四课超声波雷达",
							'bg':'imgT/radar.jpg'
						},
						{
							'title':"T2第五课巡线小皮卡",
							'bg':'imgT/patrolCar.jpg'
						},
						{
							'title':"T2第六课拳击机器人",
							'bg':'imgT/boxing.jpg'
						},
						{
							'title':"T3第一课伐木搬运车",
							'bg':'imgT/carry.jpg'
						},
						{
							'title':"T3第二课汉格瑞摩托",
							'bg':'imgT/motor.jpg'
						},
						{
							'title':"T3第三课推土车山猫",
							'bg':'imgT/bulldozer.jpg'
						},
						{
							'title':"T3第四课加特林机枪",
							'bg':'imgT/gatling.jpg'
						},
						{
							'title':"T3第五课智能电风扇",
							'bg':'imgT/fans.jpg'
						},
						{
							'title':"T3第六课克林分拣机",
							'bg':'imgT/filter.jpg'
						},
						{
							'title':"T4第一课闪电狗波特",
							'bg':'imgT/dog.jpg'
						},
						{
							'title':"T4第二课HELLO鹦鹉",
							'bg':'imgT/birdT4.jpg'
						},
						{
							'title':"T4第三课爆笑毛毛虫",
							'bg':'imgT/caterpillar.jpg'
						},
						{
							'title':"T4第四课虎式坦克车",
							'bg':'imgT/tank.jpg'
						},
						{
							'title':"T4第五课才艺王瓦力",
							'bg':'imgT/wallE.jpg'
						},
						{
							'title':"T4第六课阿斯顿马丁",
							'bg':'imgT/aston.jpg'
						},
						{
							'title':"T5第一课艾德曼钢爪",
							'bg':'imgT/claw.jpg'
						},
						{
							'title':"T5第二课巧手工程车",
							'bg':'imgT/carT5.jpg'
						},
						{
							'title':"T5第三课比格电叉车",
							'bg':'imgT/forklift.jpg'
						},
						{
							'title':"T5第四课纤毫毕现称",
							'bg':'imgT/weighing.jpg'
						},
						{
							'title':"T5第课赛特排雷车",
							'bg':'imgT/mine.jpg'
						},
						{
							'title':"T5第课自导向物流",
							'bg':'imgT/logistics.jpg'
						},
					],
					'A级别':[
						//课程A级别暂时未开通，开通可以填写数据
						{
							'title':"T1第一课魔力赛车",
							'bg':'imgT/racing.jpg'
						},
						{
							'title':"T1第二课吉猫凯蒂",
							'bg':'imgT/cat.jpg'
						},
						{
							'title':"T1第三课雷神战车",
							'bg':'imgT/thor.jpg'
						},
						{
							'title':"T1第四课恐龙先锋",
							'bg':'imgT/dinosaurT1.jpg'
						},
						{
							'title':"T1第五课飞鸟伯德",
							'bg':'imgT/bird.jpg'
						},
						{
							'title':"T1第六课灌篮高手",
							'bg':'imgT/basket.jpg'
						},
						{
							'title':"T2第一课哥利亚战车",
							'bg':'imgT/geliya.jpg'
						},
						{
							'title':"T2第二课弗兰德台灯",
							'bg':'imgT/fulande.jpg'
						},
						{
							'title':"T2第三课追日震天弩",
							'bg':'imgT/followthesun.jpg'
						},
						{
							'title':"T2第四课超声波雷达",
							'bg':'imgT/radar.jpg'
						},
						{
							'title':"T2第五课巡线小皮卡",
							'bg':'imgT/patrolCar.jpg'
						},
						{
							'title':"T2第六课拳击机器人",
							'bg':'imgT/boxing.jpg'
						},
						{
							'title':"T3第一课伐木搬运车",
							'bg':'imgT/carry.jpg'
						},
						{
							'title':"T3第二课汉格瑞摩托",
							'bg':'imgT/motor.jpg'
						},
						{
							'title':"T3第三课推土车山猫",
							'bg':'imgT/bulldozer.jpg'
						},
						{
							'title':"T3第四课加特林机枪",
							'bg':'imgT/gatling.jpg'
						},
						{
							'title':"T3第五课智能电风扇",
							'bg':'imgT/fans.jpg'
						},
						{
							'title':"T3第六课克林分拣机",
							'bg':'imgT/filter.jpg'
						},
						{
							'title':"T4第一课闪电狗波特",
							'bg':'imgT/dog.jpg'
						},
						{
							'title':"T4第二课HELLO鹦鹉",
							'bg':'imgT/birdT4.jpg'
						},
						{
							'title':"T4第三课爆笑毛毛虫",
							'bg':'imgT/caterpillar.jpg'
						},
						{
							'title':"T4第四课虎式坦克车",
							'bg':'imgT/tank.jpg'
						},
						{
							'title':"T4第五课才艺王瓦力",
							'bg':'imgT/wallE.jpg'
						},
						{
							'title':"T4第六课阿斯顿马丁",
							'bg':'imgT/aston.jpg'
						},
						{
							'title':"T5第一课艾德曼钢爪",
							'bg':'imgT/claw.jpg'
						},
						{
							'title':"T5第二课巧手工程车",
							'bg':'imgT/carT5.jpg'
						},
						{
							'title':"T5第三课比格电叉车",
							'bg':'imgT/forklift.jpg'
						},
						{
							'title':"T5第四课纤毫毕现称",
							'bg':'imgT/weighing.jpg'
						},
						{
							'title':"T5第课赛特排雷车",
							'bg':'imgT/mine.jpg'
						},
						{
							'title':"T5第课自导向物流",
							'bg':'imgT/logistics.jpg'
						},
					],
					'E级别':[
						//课程E级别暂时未开通，开通可以填写数
						{
							'title':"T1第一课魔力赛车",
							'bg':'imgT/racing.jpg'
						},
						{
							'title':"T1第二课吉猫凯蒂",
							'bg':'imgT/cat.jpg'
						},
						{
							'title':"T1第三课雷神战车",
							'bg':'imgT/thor.jpg'
						},
						{
							'title':"T1第四课恐龙先锋",
							'bg':'imgT/dinosaurT1.jpg'
						},
						{
							'title':"T1第五课飞鸟伯德",
							'bg':'imgT/bird.jpg'
						},
						{
							'title':"T1第六课灌篮高手",
							'bg':'imgT/basket.jpg'
						},
						{
							'title':"T2第一课哥利亚战车",
							'bg':'imgT/geliya.jpg'
						},
						{
							'title':"T2第二课弗兰德台灯",
							'bg':'imgT/fulande.jpg'
						},
						{
							'title':"T2第三课追日震天弩",
							'bg':'imgT/followthesun.jpg'
						},
						{
							'title':"T2第四课超声波雷达",
							'bg':'imgT/radar.jpg'
						},
						{
							'title':"T2第五课巡线小皮卡",
							'bg':'imgT/patrolCar.jpg'
						},
						{
							'title':"T2第六课拳击机器人",
							'bg':'imgT/boxing.jpg'
						},
						{
							'title':"T3第一课伐木搬运车",
							'bg':'imgT/carry.jpg'
						},
						{
							'title':"T3第二课汉格瑞摩托",
							'bg':'imgT/motor.jpg'
						},
						{
							'title':"T3第三课推土车山猫",
							'bg':'imgT/bulldozer.jpg'
						},
						{
							'title':"T3第四课加特林机枪",
							'bg':'imgT/gatling.jpg'
						},
						{
							'title':"T3第五课智能电风扇",
							'bg':'imgT/fans.jpg'
						},
						{
							'title':"T3第六课克林分拣机",
							'bg':'imgT/filter.jpg'
						},
						{
							'title':"T4第一课闪电狗波特",
							'bg':'imgT/dog.jpg'
						},
						{
							'title':"T4第二课HELLO鹦鹉",
							'bg':'imgT/birdT4.jpg'
						},
						{
							'title':"T4第三课爆笑毛毛虫",
							'bg':'imgT/caterpillar.jpg'
						},
						{
							'title':"T4第四课虎式坦克车",
							'bg':'imgT/tank.jpg'
						},
						{
							'title':"T4第五课才艺王瓦力",
							'bg':'imgT/wallE.jpg'
						},
						{
							'title':"T4第六课阿斯顿马丁",
							'bg':'imgT/aston.jpg'
						},
						{
							'title':"T5第一课艾德曼钢爪",
							'bg':'imgT/claw.jpg'
						},
						{
							'title':"T5第二课巧手工程车",
							'bg':'imgT/carT5.jpg'
						},
						{
							'title':"T5第三课比格电叉车",
							'bg':'imgT/forklift.jpg'
						},
						{
							'title':"T5第四课纤毫毕现称",
							'bg':'imgT/weighing.jpg'
						},
						{
							'title':"T5第课赛特排雷车",
							'bg':'imgT/mine.jpg'
						},
						{
							'title':"T5第课自导向物流",
							'bg':'imgT/logistics.jpg'
						},
					],
					'M级别':[
						//课程M级别，开通可以填写数据
					]
			
			}
		}
	}
		
	componentWillMount () {
		document.title="ZeroBlockly - 课程中心";
	}
	
	handleClick = (index) => {
		if(index == '0'){
			store.stageNum=index;
			store.type=index;
		}else{
			// this.showConfirm();
		}
	}
	
	handleClickChoose= (item) => {
		if(item == "S级别" || item == "T级别" || item == "E级别" || item == "A级别"){
			store.name=item;
		}else{
			this.showConfirm();
		}
		
	} 
	
	showConfirm =()=> {
  		confirm({
   		 title: '内容暂未公开',
   		 content: '详情请拨打服务热线：021-5650 2689',
   		 okText: '确认',
    	cancelText: '取消',
   		 onOk() {},
 	   	 onCancel() {},
 	 });
	}

	
	componentWillUnmount () {
		if(document.body.scrollTop){
			document.body.scrollTop = 0;
		}else{
			document.documentElement.scrollTop = 0;
		}
	}
	
	render() {
		let items = [];
		this.state.stage[store.stageNum].map((item , index)=>{
			items.push(<Col key={index} span={3} push={2}><span style={{backgroundColor:item==store.name?'#E50A5A':'#fff',color:item==store.name?'#fff':'#222'}} onClick={()=>this.handleClickChoose(item)} className="itemName">{item}</span></Col>)
		});				
		let courses=[],url,fun;
		this.state.data[store.name].map((item,index) => {
			if(item.title.substring(0,1) == 'S'){
				url = '/curriculum/coursePage';
				fun=null;
			}else{		
				url = '';
				fun = this.showConfirm;
			}
			
			courses.push(<Col key={index} span={7} push={index%3}>
								<div className="courseList-item" style={{backgroundImage:'url('+require('./../../image/'+item.bg)+')'}}>
									<div className="courseList-item-number">
										<span className='course-number' >{index+1}</span><span  className="triangle"></span>
									</div>
									<div className="title mtop5">
											&nbsp;&nbsp;&nbsp;
									</div>
									<div className="mtop5 sub">
										<div></div>&nbsp;
									</div>
									<div className="btm" onClick={fun}>
										<Link to={{
											pathname:url,
											state:{type:'steam',theme:'S',page:index+1}
										}}><div className="btm-text">{item.title}</div></Link>
									</div>
								</div>
							</Col>);
		})
	
		
		
		return (
			<div className="curriculum" > 
				<div className="curriculum-banner">
					<div className="curr-title">课程中心</div>
					<div className="curr-sub">多样化课程满足不同客户需求</div>
					<img className="img-currBanner" src={currBanner} />
				</div>
				<div className="curr-inner">
					<h1>课程中心</h1>
					<div className="selectList">
						<div className="selectItem">
							<h3>种类</h3>
							<Row>
								<Col span={2} push={2}><span style={{backgroundColor:0==store.type?'#E50A5A':'#fff',color:0==store.type?'#fff':'#222'}}  onClick={()=>this.handleClick(0)} className="itemName">STEAM</span></Col>
								<Col span={7} push={2}><span style={{backgroundColor:1==store.type?'#E50A5A':'#fff',color:1==store.type?'#fff':'#222'}}  onClick={()=>this.handleClick(1)}  className="itemName">中小学创客实验室</span></Col>
								<Col span={2} push={2}><span style={{backgroundColor:2==store.type?'#E50A5A':'#fff',color:2==store.type?'#fff':'#222'}}  onClick={()=>this.handleClick(2)}  className="itemName">考级</span></Col>
								<Col span={2} push={3}><span style={{backgroundColor:3==store.type?'#E50A5A':'#fff',color:3==store.type?'#fff':'#222'}}  onClick={()=>this.handleClick(3)}  className="itemName">竞赛</span></Col>
								<Col span={3} push={4}><span style={{backgroundColor:4==store.type?'#E50A5A':'#fff',color:4==store.type?'#fff':'#222'}}  onClick={()=>this.handleClick(4)}  className="itemName">通用课程</span></Col>
							</Row>
						</div>
						<div className="selectItem">
							<h3>阶段</h3>
							<Row>
								{items}
							</Row>
						</div>
					</div>
				</div>
				
				<div className="courseList">
					<div className="courseList-head">
						<span className="FL title">课程列表:</span>
						<span className="FR title">共{this.state.data[store.name].length}课</span>
					</div>
					<div className="courseList-items">
						<Row>
							{courses}
						</Row>
					</div>
				</div>
				<Footer />	
			</div>
		)
	}
}



export default Curriculum;