import React, { Component } from 'react';
import './App.css';
import { Layout } from 'antd';
import { RootHeader ,ContentRoute , FooterRoute} from './routes/index';

const { Sider , Content , Footer } = Layout;

let a=1;
class App extends Component {
  render() {
    return (
    	<Layout style={{ minHight: "100%"}}>
    		 <RootHeader />
    		<Layout>
    			<Content>
    				<ContentRoute />
    			</Content>
    		</Layout>
    	</Layout>
    );
  }
}

export default App;
//<RootHeader /> <RootHeader />



/*	<Footer>
    				<FooterRoute />
    			</Footer> 
    		*/