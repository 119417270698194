import React , {Fragment} from 'react';
import './news_body.css';
import news_1 from "../../../image/news_1.jpg";

class Newsbody extends React.Component {
	
	constructor(props){
		super(props);
		
	}
	
	render() {
		return (
			<div  style={{marginTop:'4rem'}}>
				<a style={{position:'relative'}}>
					<div className="news_body_img">
						<img src={require('./../../../image/'+this.props.info.img)} />
					</div>
					<div className="news_body_content">
						<div className="news_body_title">
							{this.props.info.title}
						</div>
						<div className="news_body_text">
						{this.props.info.content}
						</div>
						<div className="news_body_date">
							{this.props.info.time}
						</div>
					</div>
				</a>
			</div>
		)
	}
	
}
 

export default Newsbody;