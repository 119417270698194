import React from 'react';
import { Button } from 'antd';
import './item_inner.css';

class Item_inner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allData:JSON.parse(this.props.data)
		}
	}
	
	handleMouseEnter = () => {
		let box = this.refs.innerBox;
		box.style.boxShadow="0px 8px 25px rgba(0,0,0,0.12)";
		box.style.backgroundImage="url("+this.state.allData.backgroungImage+")";
		
		let img = this.refs.main_img;
		img.src=this.state.allData.frontPic;
		img.style.transform="translateY(5px)";
		
	}
	handleMouseLeave = () => {
		let box = this.refs.innerBox;
		box.style.boxShadow="0px 8px 25px rgba(0,0,0,0)";
		box.style.backgroundImage="url()";
		let img = this.refs.main_img;
		img.src=this.state.allData.behindPic;
		img.style.transform="translateY(-5px)";
	}
	
	
	render() {
		return (
			<div ref="innerBox" className="inner_box" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
				<div className="img_box">
					<img ref="main_img" className="main_img" src={this.state.allData.behindPic} />
				</div>
				<div className="item_title">
					<p>{this.state.allData.title}</p>
				</div>
				<div className="item_subheading">
					<p>版本{this.state.allData.version} &nbsp;&nbsp;&nbsp;{this.state.allData.date}</p>
				</div>
				<div className="item_button">
					<a href={this.state.allData.downLink}><Button type="primary">{this.state.allData.btn}</Button></a>
				</div>
				<div className="item_log">
					<p>更新日志</p>
				</div>
			</div>
		)
	}
}

export default Item_inner;

