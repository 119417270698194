import React from 'react';
import './production.css';
import {Row ,Col , Modal} from 'antd';
import steamBox from '../../image/steamBox.png';
import LBBox from '../../image/LBBox.png';
import LTBox from '../../image/LTBox.png';
import empty from '../../image/empty.jpg';
import { Link } from 'react-router-dom'; 
const confirm = Modal.confirm;
const data = [
	{	
		fsize:'4rem',
		heading:'STEAM课程产品',
		title:'STEAM体系产品',
		items:[{color:'#E50A5A',txt:'S'},{color:'#FEBE6D',txt:'T'},{color:'#AF2084',txt:'E'},{color:'#A6D242',txt:'A'},{color:'#4D4B49',txt:'M'}],
		pic:steamBox,
		picTxt:[['S0','S1'],['T1','T2','T3','T4','T5'],['E1','E2','E3'],['A1','A2','A3'],['M1','M2']],
		
	},{
		fsize:'1.4rem',
		heading:'考级&竞赛产品',
		title:'考级&竞赛体系产品',
		items:[{color:'#E50A5A',txt:'竞赛产品'},{color:'#FEBE6D',txt:'考级产品'}],
		pic:LTBox,
		picTxt:[['世界机器人大会竞赛集训','CICC全国青少年创客联赛套件','全国中小学科技创造大赛套件'],['中国电子学会机器人技术等级考试三级套件','中国电子学会机器人技术等级考试四级套件','美国人工智能青少年能力测评套件']]
		
	},{
		fsize:'2rem',
		heading:'创客实验室产品',
		title:'创客体系产品',
		items:[{color:'#E50A5A',txt:'创客实验室'}],
		pic:LBBox,
		picTxt:[['AI实验室建设','创客实验室建设']],
		
	},
]

export default class Production extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			num:0
		}
		
	}
	
	confirm = () => {
  		confirm({
   		 title: '商城维护中..',
   		 content: '详情请拨打服务热线：021-5650 2689',
   		 okText: '确认',
    	cancelText: '取消',
   		 onOk() {},
 	   	 onCancel() {},
 	 });
	
	}
	
	handleOver = (id,color,num) => {
		this.setState({
			num
		})
	}
	
	
	render() {
		let id;
		if(this.props.location.state){
			 id = this.props.location.state.key ;
		}else {
			id='0';
		}
		let proLeftItem = [];
		data[id].items.map((item,index)=>{
			proLeftItem.push(<div key={index} ref={item.txt} onMouseEnter={()=>this.handleOver(item.txt,item.color,index)} style={{color:this.state.num==index?'#fff':item.color,backgroundColor:this.state.num==index?item.color:'#fff'}}  className="proLeftItem" id={item.txt}> {item.txt}</div>);
		});
		let box = [];
		data[id].picTxt[this.state.num].map((item,index)=>{
			let img,code;
			if(item == ''){
				img = empty;
				code=null;
			}else{
				img = data[id].pic;
				code=(<div className="overShow"  >
												<Link to="/curriculum"><div className="proDetail">详情</div></Link>
												<Link to="/shop"><div className="proBuy">购买</div></Link>
											</div>);
			}
			
			box.push(<Col key={index} span={8}>
										<div className="proBox" onMouseEnter={this.handleMouseover} onMouseLeave={this.handleMouseleave}>
											<img src={img} />
											<div className="nowShow" style={{fontSize:data[id].fsize}}>
												{item}
											</div>
											
											{code}
										</div>
									</Col>
									)
		})
		
		return (
			<div className = "productionMain">
				<div className="prodcutionTitle">{data[id].heading}</div>
				<div className="proContent">
					<Row>
						<Col span={9}>
							<div className="proLeft">
								<div className="proLeftSub">{data[id].title}</div>
								{proLeftItem}
							</div>
						</Col>
						<Col span={15}>
							<div className="proRight">
								<Row gutter={24}>
									
									{box}
									
								</Row>
							</div>
						</Col>
					
					</Row>
				</div>
			
			</div>
		)
	}
	
	
}
