import React ,{Fragment} from 'react';
import PartOne from './home/PartOne/PartOne';//首页第一个区域
import PartTwo from './home/PartTwo/PartTwo';//首页第二个区域
import PartThree from './home/PartThree/PartThree';//首页第三个区域
import PartFour from './home/PartFour/PartFour';//首页第四个区域
import PartFive from './home/PartFive/PartFive';//首页第五个区域



class  Home extends React.Component {
	constructor(props) {
		super(props);
		this.state={
		bannerList:[
			{bg: "#f6f6f6"},
			{bg: "#87d9e1"},
			{bg: "#8185d7"},
			{bg: "#e187cf"},
			{bg: "#123456"}
		],
		offsetHieght: document.documentElement.clientHeight,
		fullPage:0,
		fullPageSlide:false,
		stay:true
	}
	}
	
	componentDidMount () {
		document.title="ZeroBlockly - 首页";
		if(document.addEventListener){
			document.addEventListener('DOMMouseScroll',this.scroll,false)
		}
		window.onmousewheel=document.onmousewheel=this.scroll;
		window.onresize=()=> {
			this.setState({
				offsetHieght: document.documentElement.clientHeight
			})
		}
	}
	componentWillUnmount () {
		this.setState({
			fullPage: 0,
			stay:false
		})
		document.removeEventListener('DOMMouseScroll',this.scroll,false);
		window.onmousewheel=document.onmousewheel=null;
		
		
	}
	pageInfo = (index) => {
		this.setState({
			fullPage: index
		})
	}
	scroll = (e) => {
		if(this.state.fullPage != 2) {
			this.child.stop();
		}
		
		e=e||window.event;
		if(this.state.fullPageSlide){
			return false;
		}
		
		if(navigator.userAgent.indexOf("Firefox")>0){
			if(e.detail > 0){
				if(this.state.fullPage >=4){
					return false;
				}
				this.setState({fullPageSlide: true});
				this.pageInfo(this.state.fullPage+1);
				setTimeout(()=>{
					this.setState({fullPageSlide: false})
				},100)
			}else {
				if(this.state.fullPage <= 0){
					return false;
				}
				this.setState({fullPageSlide: true});
				this.pageInfo(this.state.fullPage-1);
				setTimeout(()=>{
					this.setState({fullPageSlide: false})
				},100)
			}	
		}else{
		if(e.wheelDelta < 0){
			if(this.state.fullPage >=4){
				return false;
			}
			this.setState({fullPageSlide: true});
			this.pageInfo(this.state.fullPage+1);
			setTimeout(()=>{
				this.setState({fullPageSlide: false})
			},100)
		}else {
			if(this.state.fullPage <= 0){
				return false;
			}
			this.setState({fullPageSlide: true});
			this.pageInfo(this.state.fullPage-1);
			setTimeout(()=>{
				this.setState({fullPageSlide: false})
			},100)
		}
		}
	}
	handleClick = (num) => {
		this.setState({
			fullPage: num
		})
	}
	
	onRef = (ref) => {
		this.child = ref ;
	}
	
	render(){
	return (
		<Fragment>
			<div className="section" style={{'height': this.state.offsetHieght + "px"}}>
				<div className="container" style={{'transform': 'translate3d(0px,-'+this.state.fullPage*this.state.offsetHieght+'px,0px)'}}>
					<div style={{'height': this.state.offsetHieght + "px",width:"100%"}}>
						<PartOne />
					</div>
					<div style={{'height': this.state.offsetHieght + "px",width:"100%"}}>
						<PartTwo  onRef={this.onRef} />
					</div>
					<div style={{'height': this.state.offsetHieght + "px",width:"100%"}}>
						<PartThree />
					</div>
					<div style={{'height': this.state.offsetHieght + "px",width:"100%"}}>
						<PartFour />
					</div>
					<div style={{'height': this.state.offsetHieght + "px",width:"100%"}}>
						<PartFive />
					</div>
				</div>
				<div className="fixed-list">
					<div onClick={()=>this.handleClick(0)} className='fixed-bt-box'><div className="fixed-bt" style={{backgroundColor:(this.state.fullPage==0)?'#F26D8C':'#fff',height:(this.state.fullPage==0)?'40px':'20px'}}></div></div>
					<div onClick={()=>this.handleClick(1)} className='fixed-bt-box'><div className="fixed-bt" style={{backgroundColor:(this.state.fullPage==1)?'#F26D8C':'#fff',height:(this.state.fullPage==1)?'40px':'20px'}}></div></div>
					<div onClick={()=>this.handleClick(2)} className='fixed-bt-box'><div className="fixed-bt" style={{backgroundColor:(this.state.fullPage==2)?'#F26D8C':'#fff',height:(this.state.fullPage==2)?'40px':'20px'}}></div></div>
					<div onClick={()=>this.handleClick(3)} className='fixed-bt-box'><div className="fixed-bt" style={{backgroundColor:(this.state.fullPage==3)?'#F26D8C':'#fff',height:(this.state.fullPage==3)?'40px':'20px'}}></div></div>
					<div onClick={()=>this.handleClick(4)} className='fixed-bt-box'><div className="fixed-bt" style={{backgroundColor:(this.state.fullPage==4)?'#F26D8C':'#fff',height:(this.state.fullPage==4)?'40px':'20px'}}></div></div>
				</div>
			</div>
		</Fragment>
	)
	}
}


export default Home;