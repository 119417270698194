import React , { Fragment } from 'react';
import './PartFour.css';
import { Row, Col } from 'antd';
import Star1 from './../../../image/starProduct1.png';
import Star2 from './../../../image/starProduct2.png';
import Star3 from './../../../image/starProduct3.png';
import Star4 from './../../../image/starProduct4.png';
import Star5 from './../../../image/starProduct5.png';
import Star6 from './../../../image/starProduct6.png';
import Star7 from './../../../image/starProduct7.png';
import Star8 from './../../../image/starProduct8.png';


export default class PartFour extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			data:[
				{url:Star1,title:'甲壳虫机器人',desc:'甲壳虫机器人是一款拥有重力感应装置的机器人，它可以通过重力感应系统，即使转换发力方向以保持在45度以内不断变化的斜坡上保持行驶，屹立不倒。'},
				{url:Star2,title:'巡线小皮卡',desc:'结合图形化编程软件，教授巡线原理以及算法。'},
				{url:Star3,title:'克林分拣机',desc:'通过学习识别不同的颜色，并定义对于不同颜色球的动作。机器学习的基础。'},
				{url:Star4,title:'HELLO鹦鹉',desc:'鹦鹉会根据距离的不同，发出不同语音。可以对声音和距离编程。'},
				{url:Star5,title:'巡线小皮卡',desc:'结合图形化编程软件，教授巡线原理以及算法。'},
				{url:Star6,title:'倔强铁甲虫',desc:'集成多种姿态传感器，可以编程完寻线、姿态保持等不同的功能。'},
				{url:Star7,title:'提升收球车',desc:'利用杠杆原理，完成对物体（球或方块）的提升。提升装置的设计同时具有存储功能。'},
				{url:Star8,title:'足球机器人',desc:'利用舵机操控前部推杆，机器人可以完成带球前进和踢球的动作。'}
				
			],
			index:0
		}
	}
	
	starHandleMouseOver = (index) => {
		this.setState({
			index
		})
	}
	
	render() {
		let module1=[];
		for(let i=0; i<4 ; i++){
			module1.push()
								}
		
		return (
			<Fragment>
				<div className="partFourMain">
					<Row>
						<Col span={12}>
							<div className="starPic">
								<img src={this.state.data[this.state.index].url} />
							</div>
							<div className="starTitle">
								{this.state.data[this.state.index].title}
							</div>
							<div className="starDetail">
								{this.state.data[this.state.index].desc}
							</div>
						</Col>
						<Col span={12}>
							<div className="starProductSingle">
								明星单品
							</div>
							<div className="starProductSub">
								最前沿最火爆的机器人产品,让你耳目一新
							</div>
							<div className="starList">
								<Row>
								
								{this.state.data.map((item,index)=>{
									if(index>=4){
										return false;
									}
									return <Fragment key={index}><Col span={5}><div className="smallStarPic-box" onMouseOver={()=>this.starHandleMouseOver(index)}><img className="smallStarPic"  key={index} src={item.url} /></div></Col><Col span={1}></Col></Fragment>
								})}
								
								
								
								</Row>
							</div>
							<div className="starList">
								<Row>
								{this.state.data.map((item,index)=>{
									if(index<4){
										return false;
									}
									return <Fragment  key={index}><Col span={5}><div className="smallStarPic-box" onMouseOver={()=>this.starHandleMouseOver(index)}><img className="smallStarPic" src={item.url} /></div></Col><Col span={1}></Col></Fragment>
								})}
								</Row>
							</div>
							
						</Col>
					</Row>
					
				</div>
				
			</Fragment>
		)
	}
}
 