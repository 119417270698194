import React from 'react';
import img1 from './../../../../image/news/CNews7.png';
import Footer from './../../../../components/Footer';
import './CNewsOne.css';


export default class CNewsSeven extends React.Component {
	
	
	render() {
		return(
			
			<div className="newsMain">
				<div><img src={img1} /></div>
				<Footer />
			</div>
		)
	}
}
