import React from 'react';


export default class Uncomplete extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			scrollTop: this.props.scrollTop,
		}
	}
	componentWillReceiveProps () {
			this.setState({
				scrollTop: this.props.scrollTop,
			});
	}
	componentDidUpdate () {
		document.getElementById('pos').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
	}
	render() {
		return (
			<div id={ this.props.scrollTop ? "pos" : "" } style={{"fontSize":"5rem","height":"130rem","width":"100%","textAlign":"center","paddingTop":"6rem","transition":"all 0.2s"}}>
					开发中{this.props.scrollTop ? "pos" : "" }
			</div>
		)
		
	}
	
}
