import React from 'react';
import Newsheader from '../news_header/news_header';
import Newsbody from '../news_body/news_body';
import './companynews.css';
import bgp from '../../../image/news_head_bg.jpg'
import t_bgp from '../../../image/head_tit_1.png';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
 
let info=[
		{
			img:'news/companynewsImg1.jpg',
			title:'零号车间X元素战队 世界机器人大赛总决赛首秀获亚军',
			content:'本次大赛分为三类独立赛事：蓝色星球、星际探索和攻城守垒。零号车间X战队本次参加的是攻城守垒赛。经过层层选拔，零号车间X战队突出重围进入总决赛，最终夺得该项赛事的亚军。',
			time:'零号车间	2018.08.02'
		},
		{
			img:'news/companynewsImg2.jpg',
			title:'学生考试，老师监考阅卷” 零号车间师生共赴全国青少年机器人技术等级考试',
			content:'2018年9月15日8点30分，中国电子学会第三期全国青少年机器人技术等级考试在全国各省、市同时启动。本次等级考试适逢全国科普日，以机器人技术为代表的青少年能力测评活动再次引爆全国科普教育届的热情。据初步统计，全国共计400余个考场近2万人次参加了本次考试。',
			time:'零号车间	2018.09.19'
		},
		{
			img:'news/companynewsImg3.jpg',
			title:'考级100%通过率！！！零号车间做得到！',
			content:'机器人等级考试证书（机器人竞赛获奖证书）对于学员参加某些特色学校的自主招生时会有提高面试评价的积极性作用。欧美等西方发达国家非常重视青少年儿童的科技创新能力，对于有参加机器人等级考试（机器人竞赛）经历的学生会有着相当高的评价，甚至相当部分一流大学在招生方面会高度认可此方面的成绩。',
			time:'零号车间	2018.11.13'
		}


]
class Companynews extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			company:{
				color:'#000',
				backgroundImage:bgp,
				title:'构筑智慧未来',
				title_bgp:t_bgp,
			}
		}
	} 
	
	componentWillMount () {
		
		document.title="ZeroBlockly - 企业新闻";
		
	}
	
	componentWillUnmount () {
		if(document.body.scrollTop){
			document.body.scrollTop = 0;
		}else{
			document.documentElement.scrollTop = 0;
		}
	}
	
	render() {
		let newsheader_data = JSON.stringify(this.state.company);
		return (
			<div>
				<Newsheader newsheader_data={newsheader_data} />
				<div className="companynews_main">
					<Link to="/CNewsOne"><Newsbody info={info[0]} /></Link>
					<Link to="/CNewsTwo"><Newsbody info={info[1]} /></Link>
					<Link to="/CNewsThree"><Newsbody info={info[2]} /></Link>
				</div>
				<Footer />
			</div>
		)
	}
}

export default Companynews;