import React , {Fragment} from 'react';
import './PartFive.css';
import {Row ,Col} from 'antd';
import Slider from './../../slider/Slider';
import Footer from './../../Footer';

export default class PartFive extends React.Component {
	constructor (props) {
		super(props);	
		this.state={
			index:0
		}
	}
	
	handleOver = (index) => {
		this.setState({
			index
		})
	}
	
	render ( ) {
		
		return (
			<Fragment>
				<div className="partFiveMain">
					<div className="partFiveTitle">
						课程产品
					</div>
					<div className="partFiveSub">
						专业团队自行研发STEAM课程体系,让学习更加系统
					</div>
					<Row>
						<Col span={24} push={5}>
							<Row>
								<Col span={2} push={0}>
									<div className="circular-box"><div className="circular ball1" onMouseOver={()=>this.handleOver(0)} style={{backgroundColor:this.state.index==0?'#E50A5A':'#dcdcdc'}}></div></div>
									<div className="serialname mtop2">S系列</div>
								</Col>
								<Col span={3} push={1}>
									<Row>
										<Col span={4} push={4}><div onMouseOver={()=>this.handleOver(1)} className="circular ball2" style={{backgroundColor:this.state.index==1?'#E50A5A':'#dcdcdc'}}></div></Col>
										<Col span={4} push={4}><div onMouseOver={()=>this.handleOver(2)} className="circular ball2" style={{backgroundColor:this.state.index==2?'#E50A5A':'#dcdcdc'}}></div></Col>
										<Col span={4} push={4}><div onMouseOver={()=>this.handleOver(3)} className="circular ball2" style={{backgroundColor:this.state.index==3?'#E50A5A':'#dcdcdc'}}></div></Col>
										<Col span={4} push={4}><div onMouseOver={()=>this.handleOver(4)} className="circular ball2" style={{backgroundColor:this.state.index==4?'#E50A5A':'#dcdcdc'}}></div></Col>
										<Col span={4} push={4}><div onMouseOver={()=>this.handleOver(5)} className="circular ball2" style={{backgroundColor:this.state.index==5?'#E50A5A':'#dcdcdc'}}></div></Col>
									</Row>
									<div  className="serialname">T系列</div>
								</Col>
								<Col span={2} push={2}>
									<Row>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
									</Row>
									<div  className="serialname">E系列</div></Col>
								<Col span={2} push={3}>
									<Row>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
									</Row>
									<div  className="serialname">A系列</div>
								</Col>
								<Col span={2} push={4}>
									<Row>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
										<Col span={8} push={4}><div className="circular ball2"></div></Col>
									</Row>
									<div  className="serialname">M系列</div>
								</Col>
							</Row>
						</Col>
					</Row>
					
					<Slider index={this.state.index} />
					<div className="footer-box">	
						<Footer />
					</div>
					
					
				</div>
			</Fragment>
			
		)
	}
	
}

